const ANIMATING_DISTANCE = "20vw";

export const motionVariantsDynamic = (distance: string) => {
  return {
    before: (direction: number) => ({
      opacity: 0,
      x: direction > 0 ? distance : `-${distance}`,
    }),
    in: {
      opacity: 1,
      x: 0,
    },
    after: (direction: number) => ({
      opacity: 0,
      x: direction < 0 ? distance : `-${distance}`,
    })
  }
};

export const motionVariants = motionVariantsDynamic(ANIMATING_DISTANCE);
